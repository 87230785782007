'use client';

import { useState } from 'react';

import SiteHeader from '~/app/(site)/components/SiteHeader';
import UserSession from '~/core/session/types/user-session';

function SiteHeaderSessionProvider(
  props: React.PropsWithChildren<{
    data: Maybe<{
      auth: UserSession['auth'];
      data: UserSession['data'];
    }>;
  }>,
) {
  const [userSession, setUserSession] = useState(props.data);

  return (
    <SiteHeader/>
    // <UserSessionContext.Provider value={{ userSession, setUserSession }}>
    //   <AuthChangeListener accessToken={props.data?.auth?.accessToken}>
    //   </AuthChangeListener>
    // </UserSessionContext.Provider>
  );
}

export default SiteHeaderSessionProvider;
